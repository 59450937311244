import { init } from '../utils/sentry'
import { useRouter } from 'next/router'

init()

function MyApp({ Component, pageProps, err }) {
  const router = useRouter()
  return <Component {...pageProps} err={err} key={router.key}  />;
}

export default MyApp;
